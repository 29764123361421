import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Navbar, Nav, Spinner, Container } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
import './App.css';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';

// Dynamic imports using React.lazy
const Home = lazy(() => import('./Home'));
const Home2 = lazy(() => import('./Home2'));
const Home4 = lazy(() => import('./Home4'));
const Test = lazy(() => import('./Test'));
const DisplayAllPages = lazy(() => import('./DisplayAllPages'));
const StoriesPage = lazy(() => import('./StoriesPage'));
const BookView = lazy(() => import('./BookView'));
const StoryView = lazy(() => import('./StoryView'));
const LessonView = lazy(() => import('./LessonView'));
const Questions = lazy(() => import('./Questions'));
const Subscription = lazy(() => import('./Subscription'));
const Publish = lazy(() => import('./publish'));
const ConfirmSubscription = lazy(() => import('./Confirm-Subscription'));
const ArabicLetters = lazy(() => import('./ArabicLetters'));
const EnglishLetters = lazy(() => import('./EnglishLetters'));
const Animals = lazy(() => import('./Animals'));
const Islam = lazy(() => import('./Islam'));
const Likes = lazy(() => import('./Likes'));
const Sound = lazy(() => import('./Sound'));
const Freebooks = lazy(() => import('./Freebooks'));
const Manhaj = lazy(() => import('./Manhaj'));
const Manahij = lazy(() => import('./Manahij'));
const Science = lazy(() => import('./Science'));
const Under5 = lazy(() => import('./Under5'));
const Over5 = lazy(() => import('./Over5'));
const Surah = lazy(() => import('./Surah'));
const CreateStory = lazy(() => import('./CreateStory'));
const UpdateInfo = lazy(() => import('./UpdateInfo'));
const SurahResources = lazy(() => import('./SurahResources'));
const MyStories = lazy(() => import('./MyStories'));
const Contact = lazy(() => import('./contact'));
const NewAuthor = lazy(() => import('./new-author'));
const FreeBook = lazy(() => import('./free-book'));
const BookDelivery = lazy(() => import('./book-delivery'));
const GenerativeDemo = lazy(() => import('./generative-demo'));
const SurahMemorize = lazy(() => import('./SurahMemorize'));
const Login = lazy(() => import('./Login'));
const Register = lazy(() => import('./register'));
const ReadingCourse = lazy(() => import('./reading-course'));
const ViewUserStory2 = lazy(() => import('./ViewUserStory2'));

const getSessionId = () => {
  let sessionId = localStorage.getItem('sessionId');
  if (!sessionId) {
    sessionId = uuidv4();
    localStorage.setItem('sessionId', sessionId);
  }
  return sessionId;
};

const logPageAccess = (profile, eventdata = null) => {
  const sessionId = getSessionId();
  const queryParams = new URLSearchParams(window.location.search);
  const getVariables = Object.fromEntries(queryParams.entries());

  const data = {
    access_time: new Date().toISOString(),
    user_name: profile ? (profile.name ? profile.name : "None") : "Guest",
    user_email: profile ? profile.email : "Guest",
    page: window.location.pathname,
    action: JSON.stringify(getVariables),
    location: 'App Component',
    session_id: sessionId,
    previous: document.referrer,
    user_agent: navigator.userAgent,
    click_data: '',
  };

  if (eventdata) {
    data.click_data = eventdata;
  }

  let randomNum = Math.random();

  axios.get('https://ipapi.co/json/')
    .then(response => {
      const locationData = {
        country: response.data.country_name,
        city: response.data.city
      };

      data.location = locationData.country + ":" + locationData.city;

      axios.post(`https://www.katakeeb.com/server-side/server-hook.php?rrr=${randomNum}`, data)
        .then(response => {
          console.log('log:', data.user_name, ' - ', data.user_email, ' SessId:', sessionId);
        })
        .catch(error => {
          console.error('Error logging:', error);
        });
    })
    .catch(error => {
      console.error('Error fetching location data:', error);
      data.location = "err:err";

      axios.post(`https://www.katakeeb.com/server-side/server-hook.php?rrr=${randomNum}`, data)
        .then(response => {
          console.log('log:', data.user_name, ' - ', data.user_email, ' SessId:', sessionId);
        })
        .catch(error => {
          console.error('Error logging:', error);
        });
    });
};

document.addEventListener('click', function (event) {
  const clickData = {
    timestamp: new Date().toISOString(),
    element: event.target.tagName,
    id: event.target.id,
    classes: event.target.className,
    page: window.location.pathname,
    href: event.target.href ? event.target.href : 'N/A',
  };

  console.log('Click event logged:', clickData);

  let savedProfile = JSON.parse(localStorage.getItem('profile'));

  logPageAccess(savedProfile, JSON.stringify(clickData));
});

function App() {
  const savedUser = JSON.parse(localStorage.getItem('loggedInUser'));
  let savedProfile = JSON.parse(localStorage.getItem('profile'));
  let [profile, setProfile] = useState(savedProfile || null);
  let [user, setUser] = useState(savedUser || null);
  let [isLoading, setIsLoading] = useState(true);
  const [redirecting, setRedirecting] = useState(false);
  let sessionId = localStorage.getItem('sessionId');

  if (!sessionId) {
    sessionId = uuidv4();
    localStorage.setItem('sessionId', sessionId);
  }

  const googleLogin = () => {
    const currentUrl = window.location.href;
    localStorage.setItem('loginRedirect', currentUrl);

    const clientId = '455530934184-74tpcuhhvur3pi383r6o508c7qlc2mc1.apps.googleusercontent.com';
    const redirectUri = window.location.origin;
    const scope = 'email profile';
    const responseType = 'code';

    const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}&access_type=offline`;

    window.location.href = url;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      handleLoginSuccess(code);
    }
  }, []);

  const handleLoginSuccess = async (code) => {
    setRedirecting(true);
    try {
      const response = await axios.post('https://www.katakeeb.com/server-side/connector.php', {
        type: 'token',
        code: code,
      });

      const userData = { access_token: response.data.access_token, id_token: response.data.id_token };
      localStorage.setItem('loggedInUser', JSON.stringify(userData));

      setUser(userData);

      await new Promise(r => setTimeout(r, 100));

      const redirectUrl = localStorage.getItem('loginRedirect');
      if (redirectUrl) {
        localStorage.removeItem('loginRedirect');
      }
      setRedirecting(false);

    } catch (error) {
      console.error('Login Failed:', error);
    }
  };

  const logOut = () => {
    localStorage.removeItem('loggedInUser');
    localStorage.removeItem('profile');
    googleLogout();
    setUser(null);
    setProfile(null);
  };

  useEffect(() => {
    if (user && !profile) {
      axios
        .get(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: 'application/json',
          },
        })
        .then((res) => {
          setProfile(res.data);
          localStorage.setItem('profile', JSON.stringify(res.data));
          setIsLoading(false);
        })
        .catch((err) => {
          logOut();
          setIsLoading(false);
          console.log(err);
        });
    } else {
      setIsLoading(false);
    }
  }, [user]);

  if (isLoading || redirecting) {
    return (
      <Container className="mt-5 text-center">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
        <p>يرجى الانتظار، قد يستغرق الأمر حوالي 30 ثانية...</p>
      </Container>
    );
  }

  logPageAccess(profile);

  return (
    <Router>
      <ScrollToTop>
        <div className="App">
          <Navbar bg="light" expand="sm">
            <Link to='/'>
              <Navbar.Brand>
                <img alt="katakeeb logo" src="http://www.katakeeb.com/katakeeb_logo.png" width="50" height="31" />Katakeeb كتاكيب
              </Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
              <Nav className="justify-content-end">
                {user ? (
                  <>
                    <Nav.Link onClick={logOut}>خروج</Nav.Link>
                    <Nav.Link href="/likes">المفضلة</Nav.Link>
                    <Nav.Link href="/update_info">قصصي</Nav.Link>
                  </>
                ) : (
                  <Nav.Link href="/register">تسجيل الدخول</Nav.Link>
                )}
                <Nav.Link href="/subscription-info">للاشتراك</Nav.Link>
                <Nav.Link href="/contact-us">اتصل بنا</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          <Suspense fallback={
            <Container className="mt-5 text-center">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
              <p>Loading...</p>
            </Container>
          }>
            <Switch>
              <Route exact path="/test" component={Test} />
              <Route exact path="/displayall" component={DisplayAllPages} />
              <Route exact path="/displayallpages" component={DisplayAllPages} />
              <Route exact path="/stories_page" component={StoriesPage} />
              <Route path="/reading-course/:step" render={(props) => <ReadingCourse key={props.match.params.step} {...props} />} />
              <Route exact path="/arabic_letters" component={ArabicLetters} />
              <Route exact path="/animals" component={Animals} />
              <Route exact path="/islam" component={Islam} />
              <Route exact path="/likes" component={Likes} />
              <Route exact path="/sound" component={Sound} />
              <Route exact path="/manhaj/:manhajid" component={Manhaj} />
              <Route exact path="/manahij" component={Manahij} />
              <Route exact path="/under5" component={Under5} />
              <Route exact path="/Over5" component={Over5} />
              <Route exact path="/surah/1/:snumber" component={Surah} />
              <Route exact path="/surah/2/:snumber" component={SurahResources} />
              <Route exact path="/surah/3/:snumber" component={SurahMemorize} />
              <Route exact path="/science_books" component={Science} />
              <Route exact path="/english_letters" component={EnglishLetters} />
              <Route exact path="/home2" component={Home2} />
              <Route exact path="/contact-us" component={Contact} />
              <Route exact path="/newauthor" component={NewAuthor} />
              <Route exact path="/publish" component={Publish} />
              <Route exact path="/free-book" component={FreeBook} />
              <Route exact path="/book-delivery" component={BookDelivery} />
              <Route path="/cat/:catId" component={Home4} />
              <Route path="/register" render={(props) => <Register user={user} login={googleLogin} {...props} />} />
              <Route exact path="/freebooks" render={(props) => <Freebooks user={user} login={googleLogin} {...props} />} />
              <Route exact path="/" render={(props) => <Home4 user={user} login={googleLogin} {...props} />} />
              <Route path="/view_user_story/:sid" render={(props) => <ViewUserStory2 user={user} login={googleLogin} {...props} />} />
              <Route path="/mystories/:currentPage" render={(props) => <MyStories user={user} login={googleLogin} {...props} />} />
              <Route path="/mystories" render={(props) => <MyStories user={user} login={googleLogin} {...props} />} />
              <Route path="/create_story" render={(props) => <CreateStory user={user} login={googleLogin} {...props} />} />
              <Route path="/update_info" render={(props) => <UpdateInfo user={user} login={googleLogin} {...props} />} />
              <Route path="/subscription-info" render={(props) => <Subscription user={user} login={googleLogin} logPageAccess={logPageAccess} {...props} />} />
              <Route path="/confirm-subscription/:checkoutSessionId" render={(props) => <ConfirmSubscription user={user} login={googleLogin} {...props} />} />
              <Route path="/قصص-اطفال/:bookId/:urlTitle" render={(props) => <BookView user={user} login={googleLogin} {...props} />} />
              <Route path="/story/:storyId" render={(props) => <StoryView user={user} login={googleLogin} {...props} />} />
              <Route path="/lesson/:lessonId" render={(props) => <LessonView user={user} login={googleLogin} {...props} />} />
              <Route path="/questions/:lessonId" render={(props) => <Questions user={user} login={googleLogin} {...props} />} />
              <Route path="/قصص-اطفال/:bookId" render={(props) => <BookView user={user} login={googleLogin} {...props} />} />
              <Route path="/generative-demo" render={(props) => <GenerativeDemo user={user} login={googleLogin} {...props} />} />
              <Route path="/login" render={(props) => <Login user={user} login={googleLogin} {...props} />} />
              <Route path="/view/:bookId" render={(props) => <BookView user={user} login={googleLogin} {...props} />} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </Suspense>
        </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
