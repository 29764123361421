import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//R1eactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(
    <GoogleOAuthProvider clientId="455530934184-74tpcuhhvur3pi383r6o508c7qlc2mc1.apps.googleusercontent.com">
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </GoogleOAuthProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
